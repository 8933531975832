import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Image from '../components/Image';
import SEO from '../components/SEO';
import TwoColumns from '../components/TwoColumns';
import Testimonial from '../components/Testimonial';
import Lottie from '../components/Lottie';
import heroAnimationData from '../assets/animations/product-hero.json';
import heroMobileAnimationData from '../assets/animations/product-hero-mobile.json';
import activateAnimationData from '../assets/animations/product-activate.json';
import predictAnimationData from '../assets/animations/product-predict.json';
import predictMobileAnimationData from '../assets/animations/product-predict-mobile.json';
import DataSources from '../components/DataSources';
import ConnectAni from '../components/ConnectAni';
import InViewMonitor from 'react-inview-monitor';
import withSizes from 'react-sizes';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      predictPlaying: false,
    };
  }
  render() {
    const { isMobile } = this.props;
    const { seo } = this.props.data.wpPage;
    const { productHero, stats, connect, predict, activate, testimonial, secretSauce, dataSources } = this.props.data.wpPage.productPage;
    return (
      <Layout>
        <SEO seo={seo} bodyAttributes={{ class: 'page--product' }} />

        {/* Hero */}
        <section className="pad-tb sm:pad-tb--xs text-center">
          <div className="container">
            <div className="max-w-lg mx-auto lg:-mb-4 relative z-10">
              <h1 className="text-72px">{productHero.textCard?.heading}</h1>
            </div>

            <div className="hidden sm:block xl:-my-12">
              {!isMobile && (
                <div className="o-ratio o-ratio--product-hero-desktop">
                  <div className="o-ratio__content">
                    <Lottie data={heroAnimationData} />
                  </div>
                </div>
              )}
            </div>

            <div className="sm:hidden mx-auto my-10">
              {isMobile && (
                <div className="o-ratio o-ratio--product-hero-mobile">
                  <div className="o-ratio__content">
                    <Lottie data={heroMobileAnimationData} />
                  </div>
                </div>
              )}
            </div>

            <div className="max-w-lg mx-auto my-5 relative z-10">
              <div className="text-22px" dangerouslySetInnerHTML={{ __html: productHero.textCard?.content }} />
            </div>

            {productHero.textCard.button?.url && (
              <Link className="button button--dark button--waves button--h57 mt-5" to={productHero.textCard.button.url}>
                {productHero.textCard.button.title}
              </Link>
            )}
          </div>
        </section>

        {/* Stats */}
        <section className="bg-tertiary pad-tb--sm sm:pad-tb--xs">
          <div className="container">
            <header className="text-center mb-12">
              <h2 className="text-42px mb-3">{stats.heading}</h2>
              <div className="text-22px">{stats.content}</div>
            </header>

            <div className="grid md:grid-cols-3 gap-6">
              {stats.stats.map((stat, i) => (
                <div key={stat.stat + i} className={`flex flex-col bg-white text-d-black text-center md:text-left`}>
                  <div className={`stat-box-line h-2 w-full ${i === 0 && 'bg-accent'} ${i === 1 && 'bg-teal'} ${i === 2 && 'bg-l-blue'}`}></div>
                  <div className="pt-12 pb-4 px-6 flex-auto flex flex-col">
                    <Image data={stat.image} className="md:self-start self-center" style={{ maxWidth: '160px', maxHeight: '31px' }} />
                    <h4 className="font-semibold leading-tight mt-4 md:mt-6 mb-1 text-94px">{stat.stat}</h4>
                    <p className="font-medium">{stat.statDescription}</p>
                    <div className="flex-auto flex justify-between items-center border-t border-tertiary mt-8 pt-4">
                      <div className="font-medium text-16px">Channels:</div>
                      <div className="flex -ml-1 flex-auto justify-end">
                        {stat.channels.map((c, index) => (
                          <div key={`stat-${i}icon-${index}`} className="pl-1 w-full" style={{ maxWidth: '32px' }}>
                            <div className="flex-auto w-full">
                              <Image data={c.image} className="w-full h-full" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Connect */}
        <section className="pad-tb--sm sm:pad-tb--xxs text-center md:text-left">
          <div className="container">
            <TwoColumns
              text={
                <div className="text-card md:max-w-md lg:-mt-10">
                  <Icon name="connect" icon={connect.icon} />
                  <h3 className="text-card__heading text-58px">{connect.textCard.heading}</h3>
                  <div className="text-card__content  md:max-w-md" dangerouslySetInnerHTML={{ __html: connect.textCard.content }} />

                  {connect.textCard.button.title.length && (
                    <Link className="text-link text-link--black" to={connect.textCard.button.url}>
                      {connect.textCard.button.title}
                    </Link>
                  )}
                </div>
              }
              image={
                <div>
                  {/* <Image data={connect.image} /> */}
                  <ConnectAni />
                </div>
              }
            />
          </div>
        </section>

        {/* Predict */}
        <section className="pad-tb--sm bg-d-black text-white">
          <div className="container">
            <div className="text-card max-w-lg mx-auto text-center">
              <Icon name="predict" icon={predict.icon} center={true} />
              <h3 className="text-card__heading text-58px">{predict.textCard.heading}</h3>
              <div className="text-card__content " dangerouslySetInnerHTML={{ __html: predict.textCard.content }} />

              {predict.textCard.button.title.length && (
                <Link className="text-link text-link--black" to={predict.textCard.button.url}>
                  {predict.textCard.button.title}
                </Link>
              )}
            </div>
            <InViewMonitor
              key={`predict-monitor`}
              onInView={() => {
                this.setState({ predictPlaying: true });
              }}
              onNotInView={() => {
                this.setState({ predictPlaying: false });
              }}
              repeatOnInView={true}
            >
              <Lottie className="sm:hidden mt-10 max-w-md mx-auto" data={predictMobileAnimationData} isStopped={!this.state.predictPlaying} options={{ loop: false }} />
              <Lottie className="hidden sm:block" data={predictAnimationData} isStopped={!this.state.predictPlaying} options={{ loop: false }} />
            </InViewMonitor>
          </div>
        </section>

        {/* Activate */}
        <section className="pad-tb--sm sm:pad-tb--lg text-center md:text-left">
          <div className="container">
            <div className="flex-grid">
              <div className="md:w-5/12">
                <div className="text-card md:max-w-md">
                  <Icon name="activate" icon={activate.icon} />
                  <h3 className="text-card__heading text-58px">{activate.textCard.heading}</h3>
                  <div className="text-card__content  md:max-w-sm" dangerouslySetInnerHTML={{ __html: activate.textCard.content }} />

                  {activate.textCard.button.title.length && (
                    <Link className="text-link text-link--black" to={activate.textCard.button.url}>
                      {activate.textCard.button.title}
                    </Link>
                  )}
                </div>
              </div>
              <div className="md:w-7/12 mt-12 md:mt-0 flex flex-col justify-center">
                <Lottie data={activateAnimationData} />
              </div>
            </div>
          </div>
        </section>

        {/* Testimonial */}
        <Testimonial data={testimonial} />

        {/* Secret sauce */}
        <section className="secret-sauce pad-tb--sm text-center lg:text-left lg:pad-tb--lg">
          <div className="container">
            <div className="flex-grid">
              <div className="lg:w-5/12 flex flex-col justify-center items-center">
                <Image data={secretSauce.image} className="object-contain max-w-12rem lg:max-w-xs w-full" />
              </div>
              <div className="lg:w-7/12 mt-12 lg:mt-0">
                <div className="text-card lg:max-w-lg mx-auto">
                  <h3 className="text-card__heading text-58px">{secretSauce.textCard.heading}</h3>
                  <div className="text-card__content mb-0imp" dangerouslySetInnerHTML={{ __html: secretSauce.textCard.content }} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <DataSources data={dataSources} />
      </Layout>
    );
  }
}

function Icon({ icon, name, center = false }) {
  return (
    <div className={`flex items-center uppercase font-bold mb-5 justify-center ${center === true ? 'md:justify-center' : 'md:justify-start'}`}>
      <Image data={icon} className="mr-4" style={{ width: '46px', height: '67px' }} />
      <div>{name}</div>
    </div>
  );
}

export default withSizes(({ width }) => ({
  isMobile: width && width < 640,
}))(Product);

export const query = graphql`
  query Product {
    wpPage(slug: { eq: "product" }) {
      title
      ...SEO
      productPage {
        productHero {
          # Mobile image
          image {
            ...Image
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
        }
        stats {
          heading
          content
          stats {
            channels {
              image {
                ...Image
              }
              # link
            }
            image {
              ...Image
            }
            stat
            statDescription
          }
        }
        activate {
          icon {
            ...Image
          }
          image {
            ...Image
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
        }
        connect {
          icon {
            ...Image
          }
          image {
            ...Image
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
        }
        dataSources {
          image {
            ...Image
          }
          sourceLogos {
            logo {
              ...Image
            }
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
        }

        predict {
          icon {
            ...Image
          }
          image {
            ...Image
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
        }
        secretSauce {
          image {
            ...Image
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
        }
        testimonial {
          backgroundColour
          cite {
            name
            role
          }
          customer {
            __typename
            # ... on WpPage_Productpage_Testimonial_Customer {
            #   id
            # }
          }
          logo {
            ...Image
          }
          quote
        }
      }
    }
  }
`;
