import React, { PureComponent } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import LogoCardBackground from '../assets/images/logo-card.svg';
import MobileLogoCardBackground from '../assets/images/mobile-logo-card.svg';
import Image from './Image';
import chunk from 'lodash/chunk';
import media from '../utils/media';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const Logos = styled.div`
  ${tw`flex-auto flex flex-col relative`}

  ${media.greaterThan('md')`
    max-width:527px;
  `}
  &:before,
  &:after {
    content: '';
    ${tw`absolute inset-x-0`}
    top:0;
    height: 15%;
    width: 100%;
    background-image: linear-gradient(0deg, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 1) 90%);
    z-index: 1;
  }

  &:after {
    top: auto;
    bottom: 0;
    transform: rotate(180deg);
  }

  .column {
    position: relative;
    top: -80px;
    &:nth-child(2n) {
      top: 0;
    }
  }

  .slick-slider,
  .slick-slider * {
    pointer-events: none;
  }
  .slick-list {
    overflow: visible;
  }
  .slick-track {
    will-change: transform;
  }
`;

const MobileLogos = styled.div`
  ${tw`relative pointer-events-none`}

  &:before,
  &:after {
    content: '';
    ${tw`absolute inset-x-0`}
    top:0;
    left: 0;
    height: 100%;
    width: 24%;
    background-image: linear-gradient(-90deg, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 1) 90%);
    z-index: 1;
  }

  &:after {
    left: auto;
    right: 0;
    transform: rotate(180deg);
  }
`;

const Card = styled.div`
  ${tw`block relative mx-auto`}
  background:url(${MobileLogoCardBackground}) no-repeat;
  width:90%;
  max-width:255px;
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 91.76%;
  }

  ${media.greaterThan('md')`
    ${tw`w-full`}
    max-width:100%;
    background:url(${LogoCardBackground}) no-repeat;
    &:before {
      padding-bottom:110%;
    }
  `}
  > * {
    ${tw`absolute inset-0 flex flex-col items-center justify-center`}
    img {
      max-width:60%;
      max-height:30%;
    }
  }
`;

export default class DataSources extends PureComponent {
  constructor(props) {
    super(props);
    this.logos = this.props.data.sourceLogos;
    this.mobileLogos = this.props.data.sourceLogos;
    this.columns = [];

    this.desktopSettings = {
      arrows: false,
      autoplay: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      draggable: false,
      vertical: true,
      slidesToShow: 3,
      slidesToScroll: 2,
      verticalSwiping: false,
      autoplaySpeed: 0,
      speed: 4000,
      cssEase: 'linear',
      swipe: false,
    };

    this.mobileSettings = {
      arrows: false,
      dots: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 4000,
      cssEase: 'linear',
      swipe: false,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };
  }

  componentWillMount() {
    if (this.logos.length <= 4) {
      this.logos = this.logos.concat(this.logos, this.logos, this.logos, this.logos);

      for (let i = this.logos.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        const temp = this.logos[i];
        this.logos[i] = this.logos[j];
        this.logos[j] = temp;
      }
    }
    this.columns = chunk(this.logos, Math.ceil(this.logos.length / 2));
  }

  render() {
    return (
      <section className="bg-tertiary overflow-hidden text-center md:text-left">
        <div className="container">
          <div className="flex flex-wrap -ml-12">
            <div className="order-1 md:order-none md:w-1/2 w-full pl-12 flex flex-col overflow-hidden">
              {/* Desktop Version */}
              <Logos>
                <div className="md:absolute inset-0 hidden md:block">
                  <div className="flex-grid h-full items-stretch">
                    {this.columns.map((col, i) => (
                      <div key={`logo-col-${i}`} className="w-1/2 flex-auto column">
                        <Slider {...this.desktopSettings} rtl={i > 0} className="h-full">
                          {col.map((logo, index) => (
                            <div key={logo.id + index} className=" flex mb-6 card-container relative">
                              <Card>
                                <div className="logo">
                                  <Image data={logo.logo} />
                                </div>
                              </Card>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    ))}
                  </div>
                </div>
              </Logos>
            </div>
            <div className="md:w-1/2 w-full pl-12">
              <div className="text-card lg:max-w-sm mx-auto pad-tb--sm pad-b--xxs md:pad-tb lg:pad-tb--xl">
                <h3 className="text-card__heading text-52px">{this.props.data.textCard.heading}</h3>
                <div className="text-card__content mb-0imp" dangerouslySetInnerHTML={{ __html: this.props.data.textCard.content }} />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile */}
        <MobileLogos className="md:hidden pad-b--xxs">
          <Slider {...this.mobileSettings}>
            {this.mobileLogos.map((logo, index) => (
              <div key={logo.id + '-mobile-' + index} className=" flex card-container relative">
                <Card>
                  <div className="logo">
                    <Image data={logo.logo} />
                  </div>
                </Card>
              </div>
            ))}
          </Slider>
        </MobileLogos>
      </section>
    );
  }
}
